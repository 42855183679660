<template>
  <div>
    <h1>Print</h1>
    <vs-button 
      size="small" 
      @click="print">Test Print</vs-button>
  </div>
</template>

<script>
// import escpos from "escpos";
// install escpos-usb adapter module manually
// escpos.USB = require("escpos-usb");
// // Select the adapter based on your printer type
// const device = new escpos.USB();
// // const device  = new escpos.Network('localhost');
// // const device  = new escpos.Serial('/dev/usb/lp0');

// const options = { encoding: "GB18030" /* default */ };
// // encoding is optional

// const printer = new escpos.Printer(device, options);

export default {
  methods: {
    print() {
      console.log("printPaper! start");
      // const device = new escpos.USB();
      // const options = { encoding: "GB18030" /* default */ };
      // const printer = new escpos.Printer(device, options);

      // device.open(function() {
      //   printer
      //     .font("a")
      //     .align("ct")
      //     .style("bu")
      //     .size(1, 1)
      //     .text("Hello world!")
      //     .text("Welcome to the Awesome-land!!!")
      //     .cut()
      //     .close();
      // });
      console.log("printPaper! end");
    },
  },
};
</script>
